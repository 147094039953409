import Image from 'next/image';
import cx from 'classnames';

function NoResults({ loading }) {
    const headingText = loading ? 'Loading Events ' : 'No Events Found';

    return (
        <>
            <div className="my-20 text-center">
                <Image src="/images/Star-Web-Grey-2.png" alt="Jews for Jesus logo" width={40} height={40} className="mx-auto" />
                <h1 className={cx('my-8 text-5xl text-charcoal dark:text-white', loading && 'loading')}>{headingText}</h1>
                {!loading && <p>Try a different event type or location</p>}
            </div>
            <style jsx>{`
                .loading:after {
                    overflow: hidden;
                    display: inline-block;
                    vertical-align: bottom;
                    -webkit-animation: ellipsis steps(4, end) 900ms infinite;
                    animation: ellipsis steps(4, end) 900ms infinite;
                    content: '...';
                    width: 0px;
                }

                @keyframes ellipsis {
                    to {
                        width: 1.25em;
                    }
                }

                @-webkit-keyframes ellipsis {
                    to {
                        width: 1.25em;
                    }
                }
            `}</style>
        </>
    );
}

export default NoResults;
